import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2aa05d62"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "doc-id" }
const _hoisted_2 = { class: "doc-meta" }
const _hoisted_3 = { class: "scanner" }
const _hoisted_4 = { class: "product" }
const _hoisted_5 = { class: "product-info" }
const _hoisted_6 = { class: "location" }
const _hoisted_7 = { class: "product-count" }
const _hoisted_8 = {
  key: 0,
  class: "action border-top"
}
const _hoisted_9 = { class: "receive-all-qty" }
const _hoisted_10 = { class: "qty-progress" }
const _hoisted_11 = { class: "po-item-history" }
const _hoisted_12 = { class: "qty-ordered" }
const _hoisted_13 = { class: "product" }
const _hoisted_14 = { class: "product-info" }
const _hoisted_15 = { class: "location" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_DxpShopifyImg = _resolveComponent("DxpShopifyImg")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_LocationPopover = _resolveComponent("LocationPopover")!
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_back_button, {
                "default-href": "/purchase-orders",
                slot: "start"
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Purchase Order Details")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.receivingHistory()))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "icon-only",
                        icon: _ctx.timeOutline
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_button, {
                    disabled: !_ctx.hasPermission(_ctx.Actions.APP_SHIPMENT_ADMIN) || _ctx.isPOReceived(),
                    onClick: _ctx.addProduct
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "icon-only",
                        icon: _ctx.addOutline
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("main", null, [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_ion_label, { class: "ion-padding" }, {
                default: _withCtx(() => [
                  _createElementVNode("h1", null, _toDisplayString(_ctx.translate("Purchase Order")) + ": " + _toDisplayString(_ctx.order.externalOrderId), 1),
                  _createElementVNode("p", null, _toDisplayString(_ctx.translate("Item count")) + ": " + _toDisplayString(_ctx.order.items.length), 1)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_ion_chip, {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.copyToClipboard(_ctx.order.orderId, 'Internal ID saved to clipboard')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.order.orderId), 1),
                    _createVNode(_component_ion_icon, { icon: _ctx.copyOutline }, null, 8, ["icon"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_badge, {
                  color: _ctx.order.orderStatusId === 'ORDER_CREATED' ? 'medium' : 'primary'
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.order.orderStatusDesc), 1)
                  ]),
                  _: 1
                }, 8, ["color"])
              ])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_input, {
                    label: _ctx.translate(_ctx.isPOReceived() ? 'Search items' : 'Scan items'),
                    "label-placement": "fixed",
                    autofocus: "",
                    modelValue: _ctx.queryString,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.queryString) = $event)),
                    onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.isPOReceived() ? _ctx.searchProduct() : _ctx.updateProductCount()), ["enter"]))
                  }, null, 8, ["label", "modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                expand: "block",
                fill: "outline",
                onClick: _ctx.scan,
                disabled: _ctx.isPOReceived()
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "start",
                    icon: _ctx.cameraOutline
                  }, null, 8, ["icon"]),
                  _createTextVNode(" " + _toDisplayString(_ctx.translate("Scan")), 1)
                ]),
                _: 1
              }, 8, ["onClick", "disabled"])
            ]),
            (!_ctx.isPOReceived())
              ? (_openBlock(), _createBlock(_component_ion_item, {
                  key: 0,
                  lines: "none"
                }, {
                  default: _withCtx(() => [
                    (_ctx.getPOItems('pending').length > 1)
                      ? (_openBlock(), _createBlock(_component_ion_label, {
                          key: 0,
                          color: "medium",
                          class: "ion-margin-end"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("Pending: items", { itemsCount: _ctx.getPOItems('pending').length })), 1)
                          ]),
                          _: 1
                        }))
                      : (_openBlock(), _createBlock(_component_ion_label, {
                          key: 1,
                          color: "medium",
                          class: "ion-margin-end"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("Pending: item", { itemsCount: _ctx.getPOItems('pending').length })), 1)
                          ]),
                          _: 1
                        }))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (!_ctx.isPOReceived())
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.getPOItems('pending'), (item, index) => {
                  return _withDirectives((_openBlock(), _createBlock(_component_ion_card, {
                    key: index,
                    class: _normalizeClass(_ctx.getProductIdentificationValue(_ctx.barcodeIdentifier, _ctx.getProduct(item.productId)) === _ctx.lastScannedId ? 'scanned-item' : '' ),
                    id: _ctx.getProductIdentificationValue(_ctx.barcodeIdentifier, _ctx.getProduct(item.productId))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                          _createVNode(_component_ion_item, { lines: "none" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_thumbnail, {
                                slot: "start",
                                onClick: ($event: any) => (_ctx.openImage(_ctx.getProduct(item.productId).mainImageUrl, _ctx.getProduct(item.productId).productName))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_DxpShopifyImg, {
                                    size: "small",
                                    src: _ctx.getProduct(item.productId).mainImageUrl
                                  }, null, 8, ["src"])
                                ]),
                                _: 2
                              }, 1032, ["onClick"]),
                              _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("h2", null, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) ? _ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) : _ctx.getProduct(item.productId).productName), 1),
                                  _createElementVNode("p", null, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.secondaryId, _ctx.getProduct(item.productId))), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_LocationPopover, {
                            item: item,
                            type: "order",
                            facilityId: _ctx.currentFacility.facilityId
                          }, null, 8, ["item", "facilityId"])
                        ]),
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode(_component_ion_item, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_input, {
                                label: _ctx.translate('Qty'),
                                "label-placement": "floating",
                                type: "number",
                                value: "0",
                                min: "0",
                                modelValue: item.quantityAccepted,
                                "onUpdate:modelValue": ($event: any) => ((item.quantityAccepted) = $event),
                                disabled: _ctx.isForceScanEnabled
                              }, null, 8, ["label", "modelValue", "onUpdate:modelValue", "disabled"])
                            ]),
                            _: 2
                          }, 1024)
                        ])
                      ]),
                      (item.quantity > 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createElementVNode("div", _hoisted_9, [
                              _createVNode(_component_ion_button, {
                                onClick: ($event: any) => (_ctx.receiveAll(item)),
                                disabled: _ctx.isForceScanEnabled || _ctx.isItemReceivedInFull(item),
                                slot: "start",
                                size: "small",
                                fill: "outline"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.translate("Receive All")), 1)
                                ]),
                                _: 2
                              }, 1032, ["onClick", "disabled"])
                            ]),
                            _createElementVNode("div", _hoisted_10, [
                              _createVNode(_component_ion_progress_bar, {
                                color: _ctx.getRcvdToOrderedFraction(item) === 1 ? 'success' : _ctx.getRcvdToOrderedFraction(item) > 1 ? 'danger' : 'primary',
                                value: _ctx.getRcvdToOrderedFraction(item)
                              }, null, 8, ["color", "value"])
                            ]),
                            _createElementVNode("div", _hoisted_11, [
                              _createVNode(_component_ion_chip, {
                                outline: "",
                                onClick: ($event: any) => (_ctx.receivingHistory(item.productId))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, { icon: _ctx.checkmarkDone }, null, 8, ["icon"]),
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.getPOItemAccepted(item.productId)) + " " + _toDisplayString(_ctx.translate("received")), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _createElementVNode("div", _hoisted_12, [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.quantity) + " " + _toDisplayString(_ctx.translate("ordered")), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["class", "id"])), [
                    [_vShow, item.orderItemStatusId !== 'ITEM_COMPLETED' && item.orderItemStatusId !== 'ITEM_REJECTED']
                  ])
                }), 128))
              : _createCommentVNode("", true),
            (!_ctx.isPOReceived())
              ? (_openBlock(), _createBlock(_component_ion_item, {
                  key: 2,
                  lines: "none"
                }, {
                  default: _withCtx(() => [
                    (_ctx.getPOItems('completed').length > 1)
                      ? (_openBlock(), _createBlock(_component_ion_text, {
                          key: 0,
                          color: "medium",
                          class: "ion-margin-end"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("Completed: items", { itemsCount: _ctx.getPOItems('completed').length })), 1)
                          ]),
                          _: 1
                        }))
                      : (_openBlock(), _createBlock(_component_ion_text, {
                          key: 1,
                          color: "medium",
                          class: "ion-margin-end"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("Completed: item", { itemsCount: _ctx.getPOItems('completed').length })), 1)
                          ]),
                          _: 1
                        })),
                    (_ctx.getPOItems('completed').length)
                      ? (_openBlock(), _createBlock(_component_ion_button, {
                          key: 2,
                          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showCompletedItems = !_ctx.showCompletedItems)),
                          color: "medium",
                          fill: "clear"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              icon: _ctx.showCompletedItems ? _ctx.eyeOutline : _ctx.eyeOffOutline,
                              slot: "icon-only"
                            }, null, 8, ["icon"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getPOItems('completed'), (item, index) => {
              return _withDirectives((_openBlock(), _createBlock(_component_ion_card, {
                key: index,
                class: _normalizeClass(_ctx.getProductIdentificationValue(_ctx.barcodeIdentifier, _ctx.getProduct(item.productId)) === _ctx.lastScannedId ? 'scanned-item' : '' ),
                id: _ctx.getProductIdentificationValue(_ctx.barcodeIdentifier, _ctx.getProduct(item.productId))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createVNode(_component_ion_item, { lines: "none" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_thumbnail, {
                            slot: "start",
                            onClick: ($event: any) => (_ctx.openImage(_ctx.getProduct(item.productId).mainImageUrl, _ctx.getProduct(item.productId).productName))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_DxpShopifyImg, {
                                size: "small",
                                src: _ctx.getProduct(item.productId).mainImageUrl
                              }, null, 8, ["src"])
                            ]),
                            _: 2
                          }, 1032, ["onClick"]),
                          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                            default: _withCtx(() => [
                              _createElementVNode("h2", null, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) ? _ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) : _ctx.getProduct(item.productId).productName), 1),
                              _createElementVNode("p", null, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.secondaryId, _ctx.getProduct(item.productId))), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      _createVNode(_component_ion_chip, {
                        disabled: true,
                        outline: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: _ctx.locationOutline }, null, 8, ["icon"]),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.locationSeqId), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _createElementVNode("div", null, [
                      _createVNode(_component_ion_item, { lines: "none" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { slot: "end" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("/ received", { receivedCount: _ctx.getPOItemAccepted(item.productId), orderedCount: item.quantity })), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_ion_icon, {
                            icon: (_ctx.getPOItemAccepted(item.productId) == item.quantity) ? _ctx.checkmarkDoneCircleOutline : _ctx.warningOutline,
                            color: (_ctx.getPOItemAccepted(item.productId) == item.quantity) ? '' : 'warning',
                            slot: "end"
                          }, null, 8, ["icon", "color"])
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ])
                ]),
                _: 2
              }, 1032, ["class", "id"])), [
                [_vShow, _ctx.showCompletedItems && item.orderItemStatusId === 'ITEM_COMPLETED']
              ])
            }), 128))
          ])
        ]),
        _: 1
      }),
      (!_ctx.isPOReceived())
        ? (_openBlock(), _createBlock(_component_ion_footer, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_buttons, { slot: "end" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        fill: "outline",
                        size: "small",
                        color: "primary",
                        disabled: !_ctx.hasPermission(_ctx.Actions.APP_SHIPMENT_UPDATE),
                        class: "ion-margin-end",
                        onClick: _ctx.closePO
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Receive And Close")), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled", "onClick"]),
                      _createVNode(_component_ion_button, {
                        fill: "solid",
                        size: "small",
                        color: "primary",
                        disabled: !_ctx.hasPermission(_ctx.Actions.APP_SHIPMENT_UPDATE) || !_ctx.isEligibileForCreatingShipment(),
                        onClick: _ctx.savePODetails
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Receive")), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled", "onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}